import { useCallback, useEffect } from 'react';
import {
  INFO,
  WARN,
  ERROR,
  NotificationType,
  useNotifications,
} from './NotificationProvider';

const BG_COLORS = {
  [INFO]: 'bg-ron-light border-ron-dark',
  [WARN]: 'bg-harry border-harry-dark',
  [ERROR]: 'bg-draco border-draco-dark text-white',
};

const REMOVE_TIMEOUT_MS = {
  [INFO]: 5_000,
  [WARN]: 10_000,
  [ERROR]: 10_000,
};

type NotificationItemType = {
  notification: NotificationType;
  onRemoveNotification: (id: string) => void;
};

const NotificationItem = ({
  notification: { id, level, message },
  onRemoveNotification,
}: NotificationItemType) => {
  const handleRemove = useCallback(
    () => onRemoveNotification(id as string),
    [id, onRemoveNotification]
  );

  useEffect(() => {
    const timeoutId = window.setTimeout(
      () => handleRemove(),
      REMOVE_TIMEOUT_MS[level]
    );

    return () => window.clearTimeout(timeoutId);
  }, [handleRemove, level]);

  return (
    <div
      role="alert"
      className={`w-3/4 rounded p-2 mt-2 max-w-[300px] line-clamp-3 break-words drop-shadow-md border animate-fadeIn ${BG_COLORS[level]}`}
      onClick={handleRemove}
    >
      {message}
    </div>
  );
};

const NotificationCenter = () => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <div className="fixed z-40 w-screen flex flex-col items-center">
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onRemoveNotification={removeNotification}
        />
      ))}
    </div>
  );
};

export default NotificationCenter;
