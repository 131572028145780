import { useCallback, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import NotificationProvider, {
  ERROR,
  useNotifications,
} from './notifications/NotificationProvider';
import ConfirmModalProvider from './ui/confirm';
import NotificationCenter from './notifications/NotificationCenter';
import { AuthProvider, useAuth } from './auth';
import { getFamilyForUser } from './auth/families';
import AppWithNav from './nav';
import MealsPage from './meals';
import MenusPage from './menus';
import RecipesPage from './recipes';
import LabelsPage from './labels';
import SidesPage from './sides';
import LoginPage from './login';
import InvitePage from './invite';
import LoadingSpinner from './ui/loadingSpinner';
import ErrorPage from './ErrorPage';
import ServiceWorkerUI from './notifications/ServiceWorkerUI';
import PushNotificationUI from './notifications/PushNotificationUI';

const RedirectToFamilyUrl = ({
  app,
  path,
}: {
  app?: string;
  path?: string;
}) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // Need to lookup the vanity url
  useEffect(() => {
    let mounted = true;

    if (!auth.uid) {
      return;
    }

    const pathParts = location.pathname.split('/');
    const appPart = app || pathParts.slice(0, 2).join('/');
    const pathPart = path || pathParts.slice(3).join('/');
    const searchPart = location.search ? `?${location.search}` : '';
    const hashPart = location.hash ? `#${location.hash}` : '';

    getFamilyForUser(auth.uid).then((family) => {
      if (!mounted) {
        return;
      }

      navigate(
        `${appPart}/${family.vanityId}/${pathPart}${searchPart}${hashPart}`
      );
    });

    return () => {
      mounted = false;
    };
  }, [app, auth, location, navigate, path]);

  return <LoadingSpinner />;
};

const RequiredAuth = () => {
  const auth = useAuth();
  const location = useLocation();
  const { familyId } = useParams();

  if (!auth.uid) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (familyId && !auth.validateAccess(familyId)) {
    return <RedirectToFamilyUrl />;
  }

  return (
    <AppWithNav>
      <PushNotificationUI />
      <Outlet />
    </AppWithNav>
  );
};

const LookupFamily = () => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.uid || !auth.familyId) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <RedirectToFamilyUrl app="menu" path="menus" />;
};

const GlobalErrorHandler = () => {
  const { addNotification } = useNotifications();

  const handleError = useCallback(() => {
    addNotification({
      level: ERROR,
      message: 'Sorry, something went wrong. Please try again.',
    });
  }, [addNotification]);

  const handleRejection = useCallback(() => {
    addNotification({
      level: ERROR,
      message: 'Sorry, something went wrong. Please try again.',
    });
  }, [addNotification]);

  useEffect(() => {
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  }, [handleError, handleRejection]);

  return null;
};

const App = () => {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <NotificationProvider>
        <GlobalErrorHandler />
        <ServiceWorkerUI />
        <AuthProvider>
          <ConfirmModalProvider>
            <NotificationCenter />
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/invite/*" element={<InvitePage />} />
              <Route path="/menu/:familyId" element={<RequiredAuth />}>
                <Route path="meals/*" element={<MealsPage />} />
                <Route path="menus/*" element={<MenusPage />} />
                <Route path="sides/*" element={<SidesPage />} />
                <Route path="labels/*" element={<LabelsPage />} />
                <Route path="recipes/*" element={<RecipesPage />} />
              </Route>
              <Route path="*" element={<LookupFamily />} />
            </Routes>
          </ConfirmModalProvider>
        </AuthProvider>
      </NotificationProvider>
    </ErrorBoundary>
  );
};

export default App;
