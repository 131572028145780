import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../ui/modal';
import ModalTitle from '../ui/modal/title';
import ModalBody from '../ui/modal/body';
import ModalFooter from '../ui/modal/footer';
import TextBox from '../form/textbox';
import Button from '../form/button';
import { useDebounce } from '../lib/useDebounce';
import { useGetLabelById } from './hooks/useGetLabelById';
import { useUpdateLabel } from './hooks/useUpdateLabel';
import { useDeleteLabel } from './hooks/useDeleteLabel';

const UpdateLabelModal = () => {
  const { labelId = '' } = useParams();
  const navigate = useNavigate();

  const handleClose = useCallback(
    () => navigate('../', { replace: true }),
    [navigate]
  );

  const {data: label } = useGetLabelById(labelId);
  const [name, setName] = useState<string>();

  if (!!label && !name) {
    setName(label.name);
  }

  const updateLabel = useUpdateLabel();

  const debounceSave = useDebounce(() => {
    if (label && name) {
      updateLabel({ ...label, name });
    }
  });

  const handleNameChange = (newName: string) => {
    setName(newName.toLowerCase());
    debounceSave();
  };

  const deleteLabel = useDeleteLabel();
  const handleDeleteLabel = useCallback(() => {
    if (!label) {
      return;
    }

    deleteLabel(label.id).then(handleClose);
  }, [deleteLabel, label, handleClose]);

  return (
    <Modal>
      <ModalTitle onClose={handleClose}>Create Label</ModalTitle>
      <ModalBody>
        <div>
          <TextBox label="Name" onChange={handleNameChange} value={name} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button use="destructive" onClick={handleDeleteLabel}>
          Delete
        </Button>
        <Button use="secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateLabelModal;
