import { useCallback, useState } from 'react';
import Modal from '../ui/modal';
import ModalTitle from '../ui/modal/title';
import ModalBody from '../ui/modal/body';
import { Menu, MenuEgg, DayOfWeek } from './types';
import MealPicker from './mealPicker';
import ModalFooter from '../ui/modal/footer';
import Button from '../form/button';
import SidePicker from './sidePicker';
import TabBar from '../ui/tabBar';

const TAB_MEALS = 'TAB_MEALS';
const TAB_SIDES = 'TAB_SIDES';
const TABS = [
  { id: TAB_MEALS, label: 'Meals' },
  { id: TAB_SIDES, label: 'Sides' },
];

type MenuPickerModalPropsType = {
  title: string;
  week: Menu | MenuEgg;
  day: DayOfWeek;
  onCancel: () => void;
  onSave: ({
    mealIds,
    sideIds,
  }: {
    mealIds: Array<string>;
    sideIds: Array<string>;
  }) => void;
};

const MenuPickerModal = ({
  title,
  week,
  day,
  onCancel,
  onSave,
}: MenuPickerModalPropsType) => {
  const [mealIds, setMealIds] = useState(week.days[day].dinnerIds || []);
  const [sideIds, setSideIds] = useState(week.days[day].sideIds || []);
  const [tabId, setTabId] = useState(TAB_MEALS);

  const handleToggleMeal = useCallback((mealId: string) => {
    setMealIds((oldMealIds) => {
      if (oldMealIds.includes(mealId)) {
        return oldMealIds.filter((id) => mealId !== id);
      }

      return [...oldMealIds, mealId];
    });
  }, []);

  const handleToggleSide = useCallback((sideId: string) => {
    setSideIds((oldSideIds) => {
      if (oldSideIds.includes(sideId)) {
        return oldSideIds.filter((id) => sideId !== id);
      }

      return [...oldSideIds, sideId];
    });
  }, []);

  const handleSave = useCallback(() => {
    onSave({ mealIds, sideIds });
  }, [onSave, mealIds, sideIds]);

  const handleTabChange = useCallback((tabId) => {
    setTabId(tabId);
  }, []);

  return (
    <Modal>
      <ModalTitle onClose={onCancel}>{title}</ModalTitle>
      <ModalBody>
        <TabBar activeId={tabId} tabs={TABS} onChange={handleTabChange} />
        {tabId === TAB_MEALS && (
          <MealPicker mealIds={mealIds} onToggleItem={handleToggleMeal} />
        )}
        {tabId === TAB_SIDES && (
          <SidePicker sideIds={sideIds} onToggleItem={handleToggleSide} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button use="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSave}>OK</Button>
      </ModalFooter>
    </Modal>
  );
};

export default MenuPickerModal;
