import { PropsWithChildren, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toWeek } from '../lib/toWeek';
import { fromWeek } from '../lib/fromWeek';
import {
  DayOfWeek,
  FRIDAY,
  MONDAY,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
} from './types';
import { startOfWeek } from '../lib/startOfWeek';

type NextPrevWeekProps = {
  onClick: () => void;
};

const NextPrevWeek = ({ onClick, children }: PropsWithChildren<NextPrevWeekProps>) => {
  return (
    <div
      className="flex flex-col justify-center border-y py-3 text-center"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

type DayTilePropsType = {
  active: boolean;
  dayOfWeek: DayOfWeek;
  onClick: (dayOfWeek: DayOfWeek) => void;
};

const DayTile = ({
  active,
  children,
  dayOfWeek,
  onClick,
}: PropsWithChildren<DayTilePropsType>) => {
  const handleClick = useCallback(
    () => onClick(dayOfWeek),
    [dayOfWeek, onClick]
  );

  return (
    <div
      className={`flex flex-col justify-center grow border-y text-center ${
        active ? 'bg-harry-light' : ''
      }`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

const WeekNavigatorStrip = () => {
  const params = useParams();
  const navigate = useNavigate();

  const handleGoToDay = useCallback(
    (dayOfWeek: DayOfWeek) => {
      navigate(`../${params.week}/${dayOfWeek}`, { replace: true });
    },
    [navigate, params]
  );

  const handleGoToPreviousWeek = useCallback(() => {
    const week = fromWeek(params.week);
    navigate(
      `../${toWeek(startOfWeek(new Date(week.setDate(week.getDate() - 7))))}/SUNDAY`
    );
  }, [navigate, params]);

  const handleGoToNextWeek = useCallback(() => {
    const week = fromWeek(params.week);
    navigate(
      `../${toWeek(startOfWeek(new Date(week.setDate(week.getDate() + 7))))}/SUNDAY`
    );

  }, [navigate, params]);

  return (
    <div className="flex flex-col items-stretch bg-slate-50 w-button-lg">
      <NextPrevWeek onClick={handleGoToPreviousWeek}>︽</NextPrevWeek>
      <DayTile
        active={params.day === SUNDAY}
        dayOfWeek={SUNDAY}
        onClick={handleGoToDay}
      >
        SUN
      </DayTile>
      <DayTile
        active={params.day === MONDAY}
        dayOfWeek={MONDAY}
        onClick={handleGoToDay}
      >
        MON
      </DayTile>
      <DayTile
        active={params.day === TUESDAY}
        dayOfWeek={TUESDAY}
        onClick={handleGoToDay}
      >
        TUE
      </DayTile>
      <DayTile
        active={params.day === WEDNESDAY}
        dayOfWeek={WEDNESDAY}
        onClick={handleGoToDay}
      >
        WED
      </DayTile>
      <DayTile
        active={params.day === THURSDAY}
        dayOfWeek={THURSDAY}
        onClick={handleGoToDay}
      >
        THU
      </DayTile>
      <DayTile
        active={params.day === FRIDAY}
        dayOfWeek={FRIDAY}
        onClick={handleGoToDay}
      >
        FRI
      </DayTile>
      <DayTile
        active={params.day === SATURDAY}
        dayOfWeek={SATURDAY}
        onClick={handleGoToDay}
      >
        SAT
      </DayTile>
      <NextPrevWeek onClick={handleGoToNextWeek}>︾</NextPrevWeek>
    </div>
  );
};

export default WeekNavigatorStrip;
