import { createContext, useCallback, useContext, useState } from 'react';
import Modal from './modal';
import ModalTitle from './modal/title';
import ModalBody from './modal/body';
import ModalFooter from './modal/footer';
import Button from '../form/button';

type ModalStateType = {
  message: string;
  options: Array<string>;
  onCancel: () => void;
  onResponse: (option: string) => void;
};

export type ConfirmContextType = {
  setModalState: React.Dispatch<
    React.SetStateAction<ModalStateType | undefined>
  >;
  modalState: ModalStateType | undefined;
};

export const ConfirmContext = createContext<ConfirmContextType>(null!);

const ConfirmModal = () => {
  const { setModalState, modalState } = useContext(ConfirmContext);
  const { message, options, onCancel, onResponse } = modalState || {};

  const handleClose = useCallback(() => {
    setModalState(undefined);

    if (onCancel) {
      onCancel();
    }
  }, [onCancel, setModalState]);

  const handleChoice = useCallback(
    (option: string) => {
      setModalState(undefined);

      if (onResponse) {
        onResponse(option);
      }
    },
    [onResponse, setModalState]
  );

  if (!options) {
    return null;
  }

  return (
    <Modal>
      <ModalTitle onClose={handleClose}>Confirm</ModalTitle>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        {options.map((option, index) => (
          <Button
            key={option}
            use={index === options.length - 1 ? 'primary' : 'secondary'}
            onClick={() => handleChoice(option)}
          >
            {option}
          </Button>
        ))}
      </ModalFooter>
    </Modal>
  );
};

type ConfirmModalPropsType = {};

const ConfirmModalProvider = ({
  children,
}: React.PropsWithChildren<ConfirmModalPropsType>) => {
  const [modalState, setModalState] = useState<ModalStateType | undefined>(
    undefined
  );

  return (
    <ConfirmContext.Provider value={{ modalState, setModalState }}>
      {children}
      <ConfirmModal />
    </ConfirmContext.Provider>
  );
};

export const useConfirmModal = () => {
  const { setModalState } = useContext(ConfirmContext);

  return async (message: string, ...options: Array<string>) => {
    return new Promise<string>((resolve, reject) => {
      setModalState({
        message,
        options,
        onCancel: reject,
        onResponse: resolve,
      });
    });
  };
};

export default ConfirmModalProvider;
