import {
  addDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { Label, LabelEgg } from '../types';
import { db } from './db';

export const createLabel = async (
  label: LabelEgg,
  uid: string,
  familyId: string
): Promise<Label> => {
  try {
    const q = query(db(familyId), where('name', '==', label.name));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() } as Label;
    }

    return addDoc(db(familyId), { uid, ...label }).then(({ id }) => ({
      id,
      ...label,
    }));
  } catch (e) {
    console.error('Error adding document: ', e);
  }

  throw new Error('Could not create document');
};
