import { addDoc } from "firebase/firestore";
import { db } from "./db";
import { MealEgg } from '../types';

export const createMeal = async (
  meal: MealEgg,
  uid: string,
  familyId: string
)=> {
  try {
    return addDoc(db(familyId), { uid, ...meal }).then(({ id }) => ({
      id,
      ...meal,
    }));
  } catch (e) {
    console.error('Error adding document: ', e);
  }

  throw new Error('Could not create document');
};
