import { useCallback } from 'react';
import { useAuth } from '../../auth/useAuth';
import { saveUserRating } from '../api/saveUserRating';

export const useSaveUserRating = () => {
  const { familyId, uid } = useAuth();

  return useCallback(
    (mealId: string, rating: number) => {
      if (!familyId || !uid) {
        return Promise.resolve(null);
      }

      return saveUserRating(familyId, { uid, mealId, value: rating });
    },
    [familyId, uid]
  );
};
