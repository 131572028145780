import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { Label } from '../types';
import { getLabelById } from '../api/getLabelById';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';

export const useGetLabelById = (id: string) => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<Label>) => {
      if (!id || !familyId) {
        handleData(null);
        return;
      }

      return getLabelById(familyId, id, handleData);
    },
    [familyId, id]
  );

  return useDataSubscriber(fetcher);
};
