import { doc, updateDoc } from 'firebase/firestore';
import { omit } from '../../lib/omit';
import { Side } from '../types';
import { db } from './db';

export const updateSide = async (
  familyId: string,
  side: Side
)=> {
  try {
    return updateDoc(doc(db(familyId), side.id), omit(['id'], side));
  } catch (e) {
    console.error('Error updating document', e);
  }

  throw new Error('Could not update');
};
