import { useCallback } from 'react';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { useAuth } from '../../auth';
import { getMealById } from '../api/getMealById';
import { Meal } from '../types';

export const useGetMealById = (id: string) => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<Meal>) => {
      if (!id || !familyId) {
        handleData(null);
        return;
      }

      return getMealById(familyId, id, handleData);
    },
    [familyId, id]
  );

  return useDataSubscriber(fetcher);
};
