import { useGetUserRating } from '../ratings/hooks/useGetUserRating';
import Rating from '../ratings/Rating';
import { useGetMealAverageRating } from '../ratings/hooks/useGetMealAverageRating';
import LoadingLocal from '../ui/loadingLocal';
import { useCallback } from 'react';
import { useSaveUserRating } from '../ratings/hooks/useSaveUserRating';

export type RatingSectionProps = {
  mealId: string;
};

export const RatingSection = ({ mealId }: RatingSectionProps) => {
  const { loading: userLoading, data: userData } = useGetUserRating(mealId);
  const { loading: avgLoading, data: avgData } =
    useGetMealAverageRating(mealId);
  const saveUserRating = useSaveUserRating();

  const handleRating = useCallback(
    (rating: number) => {
      saveUserRating(mealId, rating);
    },
    [mealId, saveUserRating]
  );

  if (userLoading || avgLoading) {
    return <LoadingLocal />;
  }

  return (
    <>
      <div className="mt-2">
        <Rating
          onRate={handleRating}
          value={userData ? userData.value : avgData ? avgData.average : 0}
          rated={!!userData}
        />
      </div>
      {avgData != null && (
        <div className="text-center text-lg mt-2">
          {Number(avgData.average).toFixed(1)} ({avgData.total}{' '}
          {`rating${avgData.total > 1 ? 's' : ''}`})
        </div>
      )}
    </>
  );
};
