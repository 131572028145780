import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { familyDb } from '../../auth/families';
import { Rating } from '../types';

const db = (familyId: string) => collection(familyDb(familyId), 'ratings');

export const getUserRatingQuery = ({
  familyId,
  uid,
  mealId,
}: {
  familyId: string;
  uid: string;
  mealId: string;
}) =>
  query(db(familyId), where('uid', '==', uid), where('mealId', '==', mealId));

export const getUserRating = (
  {
    familyId,
    uid,
    mealId,
  }: {
    familyId: string;
    uid: string;
    mealId: string;
  },
  onRating: OnSnapshotCallback<Rating>
) => {
  const q = getUserRatingQuery({ familyId, uid, mealId });

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    if (querySnapshot.empty) {
      onRating(null);
      return;
    }

    const [docSnapshot] = querySnapshot.docs;

    onRating({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    } as Rating);
  });

  return () => {
    unsubscribe();
  };
};
