import { useCallback} from 'react';
import { useAuth } from '../../auth';
import { listLabels } from '../api/listLabels';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { Label } from '../types';

export const useListLabels = () => {
  const { familyId } = useAuth();

  const fetcher = useCallback((handleData: OnSnapshotCallback<Array<Label>>) => {
    if (!familyId) {
        handleData(null);
      return;
    }

    return listLabels(familyId, handleData);
  }, [familyId]);

  return useDataSubscriber(fetcher);
};
