import { useCallback } from 'react';

type IconTogglePropsType = {
  ariaLabel?: string;
  icon: React.ReactElement;
  onChange: (e: React.SyntheticEvent, value: boolean) => void;
  value: boolean;
};

const IconToggle = ({ ariaLabel, icon, onChange, value }: IconTogglePropsType) => {
  const handleToggle = useCallback(
    (e: React.SyntheticEvent<HTMLElement, Event>) => {
      onChange(e, !value);
    },
    [onChange, value]
  );

  return (
    <button
      aria-label={ariaLabel}
      onClick={handleToggle}
      className="relative rounded-full bg-ron/25 w-[60px] h-button"
    >
      <div
        className={`${
          value ? 'translate-x-full bg-ron' : 'translate-x-0 bg-ron/50'
        } transition-transform top-0 absolute rounded-full w-button h-button flex items-center justify-center`}
      >
        {icon}
      </div>
    </button>
  );
};

export default IconToggle;
