import { doc, onSnapshot } from 'firebase/firestore';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { Meal } from '../types';
import { db } from './db';
import { MealNotFound } from './MealNotFound';

export const getMealById = (
  familyId: string,
  id: string,
  onMeal: OnSnapshotCallback<Meal>
) => {
  const unsubscribe = onSnapshot(doc(db(familyId), id), (documentSnapshot) => {
    if (documentSnapshot.exists()) {
      onMeal({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      } as Meal);
    } else {
      throw new MealNotFound(`Meal with id ${id} not found`);
    }
  });

  return () => {
    unsubscribe();
  };
};
