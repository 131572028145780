import { RefObject, useCallback, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type FlyOutMenuOverlayPropsType = {
  children: React.ReactNode;
  onClose: () => void;
};

const FlyOutMenuOverlay = ({
  children,
  onClose,
}: FlyOutMenuOverlayPropsType) => {
  const handleClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      onClose();
    },
    [onClose]
  );

  return createPortal(
    <div
      className="fixed w-screen h-screen inset-0 z-30 bg-transparent"
      onClick={handleClick}
    >
      {children}
    </div>,
    document.body
  );
};

type FlyOutMenuPropsType = FlyOutMenuOverlayPropsType & {
  children: React.ReactNode;
  onClose: () => void;
  tetherRef: RefObject<HTMLElement>;
};

const FlyOutMenu = ({ children, onClose, tetherRef }: FlyOutMenuPropsType) => {
  const menuRef = useRef<HTMLUListElement>(null);

  const positionMenu = useCallback(() => {
    if (!tetherRef?.current || !menuRef?.current) {
      return;
    }

    const tetherRect = tetherRef.current.getBoundingClientRect();
    const menuRect = menuRef.current.getBoundingClientRect();

    menuRef.current.style.top = `${Math.max(
      tetherRect.y - Math.floor(menuRect.height / 2),
      0
    )}px`;
    menuRef.current.style.left = `${Math.max(
      tetherRect.x - menuRect.width,
      0
    )}px`;
  }, [tetherRef, menuRef]);

  const handleScroll = useCallback(() => {
    positionMenu();
  }, [positionMenu]);

  useLayoutEffect(() => {
    positionMenu();

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, positionMenu]);

  return (
    <FlyOutMenuOverlay onClose={onClose}>
      <ul
        ref={menuRef}
        className="block fixed bg-white w-[300px] text-black border border-slate-400 rounded-md drop-shadow-xl"
      >
        {children}
      </ul>
    </FlyOutMenuOverlay>
  );
};

export default FlyOutMenu;
