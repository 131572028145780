import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { getWeeksMenu } from '../api/getWeeksMenu';
import { Menu, MenuEgg } from '../types';

export const useGetWeeksMenu = (week: string) => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<MenuEgg | Menu>) => {
      if (!familyId) {
        handleData(null);
        return;
      }

      return getWeeksMenu(familyId, week, handleData);
    },
    [familyId, week]
  );

  return useDataSubscriber(fetcher);
};
