import { useLayoutEffect, useMemo, useRef } from 'react';
import sha1 from 'sha1';

type ImageGeneratorPropsType = {
  data: string;
};

const useHashChunks = (data: string, chunkSizes: Array<number>) => {
  return useMemo(() => {
    const chunkSum = chunkSizes.reduce((acc, n) => acc + n, 0);
    const hashParts = [];
    for (let i = 0; i < data.length; i += chunkSum) {
      const chunkParts = [];

      for (let j = 0; j < chunkSizes.length; j++) {
        const offset = j > 0 ? chunkSizes[j - 1] : 0;
        chunkParts.push(data.slice(i + offset, i + offset + chunkSizes[j]));
      }

      hashParts.push(chunkParts);
    }

    return hashParts;
  }, [data, chunkSizes]);
};

const useDotData = (hashParts: Array<string>) => {
  return useMemo(() => {
    const x = parseInt(hashParts[0], 16);
    const y = parseInt(hashParts[1], 16);
    const radius = parseInt(hashParts[2], 16);
    const r = parseInt(hashParts[3], 16);
    const g = parseInt(hashParts[4], 16);
    const b = parseInt(hashParts[5], 16);

    return { x, y, radius, r, g, b };
  }, [hashParts]);
};

const getPct = (n: number) => `${(n * 100) / 16}%`;

const HashDot = ({ data }: { data: Array<string> }) => {
  const { x, y, radius, r, g, b } = useDotData(data);
  const xStyle = useMemo(() => ({ left: getPct(x) }), [x]);
  const yStyle = useMemo(() => ({ top: getPct(y) }), [y]);
  const radiusStyle = useMemo(
    () => ({ width: getPct(radius + 1), height: getPct(radius + 1) }),
    [radius]
  );
  const colorStyle = useMemo(
    () => ({ backgroundColor: `rgb(${r}, ${g}, ${b})` }),
    [r, g, b]
  );

  const style = useMemo(
    () => ({ ...xStyle, ...yStyle, ...radiusStyle, ...colorStyle }),
    [xStyle, yStyle, radiusStyle, colorStyle]
  );

  return <div className="absolute -translate-x-1/2 -translate-y-1/2 rounded-full opacity-50" style={style} />;
};

const ImageGenerator = ({ data }: ImageGeneratorPropsType) => {
  const hash = sha1(data);
  const hashParts = useHashChunks(hash, [1, 1, 1, 2, 2, 2]);
  const boxRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!boxRef.current) {
      return;
    }

    const width = boxRef.current.getBoundingClientRect().width;
    boxRef.current.style.height = `${width}px`;
  });

  return (
    <div ref={boxRef} className="bg-harry-light w-1/3 mr-2 relative overflow-hidden rounded border-slate-200 border">
      {hashParts.map((part) => (
        <HashDot key={part.join()} data={part} />
      ))}
    </div>
  );
};

export default ImageGenerator;
