import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { createMenu } from '../api/createMenu';
import { MenuEgg } from '../types';

export const useCreateMenu = () => {
  const { familyId, uid } = useAuth();

  return useCallback(
    (menu: MenuEgg) => {
      if (!familyId || !uid) {
        return Promise.reject();
      }

      return createMenu(menu, uid, familyId);
    },
    [familyId, uid]
  );
};
