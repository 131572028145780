import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { getMealsById } from '../api/getMealsById';
import { Meal } from '../types';

export const useGetMealsById = (ids: string[]) => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<Record<string, Meal>>) => {
      if (!familyId || !ids || !ids.length) {
        handleData(null);
        return;
      }

      return getMealsById(familyId, ids, handleData);
    },
    [ids, familyId]
  );

  return useDataSubscriber(fetcher);
};
