import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LABEL, trackCreate } from '../tracking';
import Modal from '../ui/modal';
import ModalTitle from '../ui/modal/title';
import ModalBody from '../ui/modal/body';
import ModalFooter from '../ui/modal/footer';
import TextBox from '../form/textbox';
import Button from '../form/button';
import { useCreateLabel } from './hooks/useCreateLabel';

const CreateLabelModal = () => {
  const navigate = useNavigate();

  const handleClose = useCallback(
    () => navigate('../', { replace: true }),
    [navigate]
  );

  const [name, setName] = useState<string>();
  const handleNameChange = (newName: string) => {
    setName(newName.toLowerCase());
  };

  const createLabel = useCreateLabel();

  const handleSave = useCallback(() => {
    if (!name) {
      return;
    }

    trackCreate(LABEL);

    createLabel({ name }).then(() => navigate('../'));
  }, [createLabel, name, navigate]);

  return (
    <Modal>
      <ModalTitle onClose={handleClose}>Create Label</ModalTitle>
      <ModalBody>
        <div>
          <TextBox label="Name" onChange={handleNameChange} value={name} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button use="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Create</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateLabelModal;
