import CloseX from '../icons/close-x';

type FloatingCancelButtonPropsType = {
  onClick: Function;
};

const FloatingCancelButton = ({ onClick }: FloatingCancelButtonPropsType) => {
  return (
    <button
      onClick={() => onClick()}
      className="bg-ron-light rounded-full w-button-lg h-button-lg fixed bottom-[46px] left-1/4 z-20"
    >
      <CloseX className="stroke-slate-600 stroke-[3px]" />
    </button>
  );
};

export default FloatingCancelButton;
