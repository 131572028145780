import { useCallback } from "react";
import { useAuth } from "../../auth/useAuth";
import { deleteSide } from "../api/deleteSide";

export const useDeleteSide = ()=> {
  const { familyId } = useAuth();

  return useCallback(
    (id: string) => {
      if (!familyId) {
        return Promise.reject();
      }

      return deleteSide(familyId, id);
    },
    [familyId]
  );
};
