import { useCallback, useEffect, useState } from 'react';

type ServiceWorkerUpdateListener = (
  registration: ServiceWorkerRegistration
) => void;

export const serviceWorkerHandler = (function () {
  let listeners: Set<ServiceWorkerUpdateListener> = new Set();

  return {
    update: (registration: ServiceWorkerRegistration) => {
      listeners.forEach((listener) => listener(registration));
    },

    addUpdateListener: (updateListener: ServiceWorkerUpdateListener) => {
      listeners.add(updateListener);
    },

    removeUpdateListener: (updateListener: ServiceWorkerUpdateListener) => {
      listeners.delete(updateListener);
    },
  };
})();

const UpdateAvailable = () => {
  const updateListener = useCallback(
    (registration: ServiceWorkerRegistration) => {
      console.log('Updating service worker...');
      registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
    },
    []
  );

  useEffect(() => {
    serviceWorkerHandler.addUpdateListener(updateListener);

    return () => serviceWorkerHandler.removeUpdateListener(updateListener);
  }, [updateListener]);

  // This used to have some UI to prompt the user to update. Now we just do it
  // silently.
  return null;
};

const OfflineWarning = () => {
  const [offline, setOffline] = useState(false);

  const handleOnline = useCallback(() => {
    setOffline(false);
  }, []);

  const handleOffline = useCallback(() => {
    setOffline(true);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [handleOnline, handleOffline]);

  if (!offline) {
    return null;
  }

  return (
    <div className="fixed z-50 w-screen flex flex-col items-center">
      <div
        role="alert"
        className={`w-3/4 rounded p-2 mt-2 max-w-[300px] line-clamp-3 break-words drop-shadow-md border animate-fadeIn bg-harry border-harry-dark`}
      >
        <strong>You are currently offline</strong>
        <br />
        No changes will be saved
      </div>
    </div>
  );
};

const ServiceWorkerUI = () => {
  return (
    <>
      <OfflineWarning />
      <UpdateAvailable />
    </>
  );
};

export default ServiceWorkerUI;
