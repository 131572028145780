import { useCallback } from 'react';
import { Fork } from '../icons/fork';

type OneForkProps = {
  color?: string;
  fillPercent: number;
  onClick: (v: number) => void;
  value: number;
};

const OneFork = ({ color, fillPercent, onClick, value }: OneForkProps) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <button
      className="flex relative justify-center w-[12.5%] h-[40px]"
      onClick={handleClick}
    >
      <Fork color={color} fillPercent={fillPercent} />
    </button>
  );
};

export type RatingProps = {
  onRate?: (value: number) => void;
  rated: boolean;
  value: number;
};

const Rating = ({ onRate, rated, value }: RatingProps) => {
  const ratings: Array<React.ReactNode> = [];

  const handleClick = useCallback(
    (v: number) => {
      if(!onRate) {
        return;
      }

      onRate(v);
    },
    [onRate]
  );

  for (let i = 0; i < 5; i++) {
    const fillPercent = Math.max(Math.min(value - i, 1), 0);

    ratings.push(
      <OneFork
        key={i}
        color={rated ? 'fill-draco-dark' : undefined}
        fillPercent={fillPercent}
        onClick={handleClick}
        value={i + 1}
      />
    );
  }

  return <div className="flex w-full justify-around">{ratings}</div>;
};

export default Rating;
