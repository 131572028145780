import { getDocs, query, where } from 'firebase/firestore';
import { Meal } from '../types';
import { db } from './db';

export const searchMealsByLabels = async (
  familyId: string,
  labels: string[]
) => {
  const resultsById: { [key: string]: Meal } = {};

  const promises = labels.map(async (label) => {
    const q = query(db(familyId), where('labels', 'array-contains', label));
    return getDocs(q).then((docSnapshot) => {
      docSnapshot.forEach((querySnapshot) => {
        resultsById[querySnapshot.id] = {
          id: querySnapshot.id,
          ...querySnapshot.data(),
        } as Meal;
      });
    });
  });

  await Promise.all(promises);

  return Object.values(resultsById);
};
