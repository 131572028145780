import { useNavigate } from 'react-router-dom';
import Label from '../icons/label';
import Menu from '../icons/menu';
import Meal from '../icons/meal';
import Side from '../icons/side';
import { useMagicMode } from './MagicModeProvider';

const EmptySpot = () => <div className="w-[40px]" />;

type NavButtonPropsType = {
  icon: React.ReactNode;
  isMagicMode: boolean;
  label: string;
  navId: string;
  onClick: Function;
};

const NavButton = ({
  icon,
  isMagicMode,
  label,
  navId,
  onClick,
}: NavButtonPropsType) => (
  <button onClick={() => onClick(navId)} className="flex flex-col items-center">
    <div className="w-button h-button p-1">{icon}</div>
    <div className={`${isMagicMode ? 'text-slate-200' : 'text-white'} text-sm`}>
      {label}
    </div>
  </button>
);

const BottomBarNav = () => {
  const { isMagicMode } = useMagicMode();
  const navigate = useNavigate();

  const handleNav = (navId: string) => {
    navigate(navId);
  };

  return (
    <div
      className={`flex justify-around fixed bottom-0 left-0 right-0 w-screen bg-hermione border-t border-white pb-2 pt-2 ${
        isMagicMode ? 'pointer-events-none bg-hermione-light' : ''
      }`}
    >
      <NavButton
        icon={
          <Menu
            className={`stroke-[3px] ${
              isMagicMode
                ? 'stroke-slate-200 fill-slate-200'
                : 'stroke-white fill-white'
            }`}
          />
        }
        isMagicMode={isMagicMode}
        navId="menus"
        label="Menus"
        onClick={handleNav}
      />
      <NavButton
        label="Meals"
        icon={
          <Meal
            className={`stroke-[3px] ${
              isMagicMode
                ? 'stroke-slate-200 fill-slate-200'
                : 'stroke-white fill-white'
            }`}
          />
        }
        isMagicMode={isMagicMode}
        navId="meals"
        onClick={handleNav}
      />
      <EmptySpot />
      <NavButton
        icon={
          <Side
            className={`stroke-[3px] ${
              isMagicMode
                ? 'stroke-slate-200 fill-slate-200'
                : 'stroke-white fill-white'
            }`}
          />
        }
        isMagicMode={isMagicMode}
        navId="sides"
        label="Sides"
        onClick={handleNav}
      />
      <NavButton
        icon={
          <Label
            className={`stroke-[3px] ${
              isMagicMode
                ? 'stroke-slate-200 fill-slate-200'
                : 'stroke-white fill-white'
            }`}
          />
        }
        isMagicMode={isMagicMode}
        navId="labels"
        label="Labels"
        onClick={handleNav}
      />
    </div>
  );
};

export default BottomBarNav;
