import { useCallback } from "react";
import { useAuth } from "../../auth/useAuth";
import { createSide } from "../api/createSide";
import { SideEgg } from "../types";

export const useCreateSide = ()=> {
  const { familyId, uid } = useAuth();

  return useCallback(
    (side: SideEgg)=> {
      if (!uid || !familyId) {
        return Promise.reject();
      }

      return createSide(side, uid, familyId);
    },
    [familyId, uid]
  );
};
