import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { familyDb } from '../../auth/families';
import { Rating } from '../types';

const db = (familyId: string) => collection(familyDb(familyId), 'ratings');

export const getMealAverageRating = (
  {
    familyId,
    mealId,
  }: {
    familyId: string;
    mealId: string;
  },
  onAverage: (avgData: { total: number; average: number } | null) => void
) => {
  const q = query(db(familyId), where('mealId', '==', mealId));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    if (querySnapshot.empty) {
      onAverage(null);
      return;
    }

    const total = querySnapshot.docs.reduce((sum, doc) => {
      return sum + (doc.data() as Rating).value;
    }, 0);

    onAverage({ total:querySnapshot.size, average: total / querySnapshot.size });
  });

  return () => {
    unsubscribe();
  };
};
