import { useCreateLabel } from '../../labels/hooks/useCreateLabel';
import { useAuth } from '../../auth';
import { useCallback } from 'react';
import { MealEgg } from '../types';
import { createMeal } from '../api/createMeal';

export const useCreateMeal = () => {
  const { familyId, uid } = useAuth();
  const createLabel = useCreateLabel();

  return useCallback(
    (meal: MealEgg) => {
      if (!uid || !familyId) {
        return Promise.reject();
      }

      meal.labels.forEach((label) => {
        createLabel({ name: label });
      });

      return createMeal(meal, uid, familyId);
    },
    [createLabel, familyId, uid]
  );
};
