import CloseX from '../icons/close-x';

type TagPropsType = {
  onRemove: Function;
  value: string;
};

const Tag = ({ onRemove, value }: TagPropsType) => (
  <div className="flex flex-nowrap items-center bg-slate-200 rounded-l-md rounded-r-md">
    <div className="whitespace-nowrap rounded border-1 px-2">{value} </div>
    <div
      className="w-button h-button bg-slate-300 rounded-r-md"
      onClick={() => onRemove(value)}
    >
      <CloseX className="stroke-draco stroke-[4px]" />
    </div>
  </div>
);

export default Tag;
