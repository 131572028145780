import { Query, QueryDocumentSnapshot, getDocs } from 'firebase/firestore';

type QueryBuilderType = <T>(subset: T[]) => Query;

export const splitQuery = async <T>(
  queryBuilder: QueryBuilderType,
  list: T[],
  size: number = 10
) => {
  let idx = 0;
  let queryResults: QueryDocumentSnapshot[] = [];
  const max = list.length;

  while (idx < max) {
    const subset = list.slice(idx, idx + size);
    const q = queryBuilder(subset);

    const querySnapshot = await getDocs(q);
    queryResults = queryResults.concat(querySnapshot.docs);

    idx += size;
  }

  return queryResults;
};
