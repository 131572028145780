import { initializeApp } from 'firebase/app';
import {
  Analytics,
  getAnalytics as getFirebaseAnalytics,
} from 'firebase/analytics';
import {
  getMessaging as getFirebaseMessaging,
  getToken,
  isSupported,
  Messaging,
} from 'firebase/messaging';
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
} from 'firebase/firestore';
import {
  Auth,
  connectAuthEmulator,
  getAuth as getFirebaseAuth,
} from 'firebase/auth';

export const firebaseConfig = {
  apiKey: 'AIzaSyAlM-Qxf6gq9JRnEyfegQTdQrYB3PF31uw',
  authDomain: 'app.mysuitefam.com',
  projectId: 'menu-magic-da27a',
  storageBucket: 'menu-magic-da27a.appspot.com',
  messagingSenderId: '680205844854',
  appId: '1:680205844854:web:d1275a85c2d7545a0bfa57',
  measurementId: 'G-E98RTTW8VZ',
};

export const firebaseInit = () => {
  const app = initializeApp(firebaseConfig);
  getFirebaseAnalytics(app);
  getFirestore(app);
};

const once = <T>(fn: () => T) => {
  let result: { [key: string]: T };

  const doOnce = () => {
    if (result) {
      return result.result;
    }

    result = { result: fn() };

    return result.result;
  };

  return () => doOnce();
};

export const getDb = once<Firestore>(() => {
  const db = getFirestore();

  if (process.env.REACT_APP_LOCAL_ENV === '1') {
    connectFirestoreEmulator(db, 'localhost', 8080);
  }

  return db;
});

export const getAuth: () => Auth = once<Auth>(() => {
  const auth = getFirebaseAuth();

  if (process.env.REACT_APP_LOCAL_ENV === '1') {
    connectAuthEmulator(auth, 'http://localhost:9099');
  }

  return auth;
});

export const getAnalytics = once<Analytics>(() => getFirebaseAnalytics());

export const getMessaging = once<Messaging>(() => {
  return getFirebaseMessaging();
});

export const getMessagingToken = () => {
  const messaging = getMessaging();

  return isSupported().then((supported) => {
    if (!supported) {
      return null;
    }

    return navigator.serviceWorker.getRegistration().then((registration) => {
      if (!registration) {
        return null;
      }

      return getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey:
          'BEjti2593fVbw6awTS5aGkAN5krp54iM-WY0QSLTjrjEoJUoLCorDba1nnJiKwwll1_b80CLEcfnUBoBNzpyT2A',
      });
    });
  });
};
