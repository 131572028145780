import { useCallback, useMemo, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CreateMealModal from '../createMealModal';
import UpdateMealModal from '../updateMealModal';
import FloatingAddButton from '../ui/floatingAddButton';
import ListHeader from '../ui/list/listHeader';
import ListItem from '../ui/list/listItem';
import ListContainer from '../ui/list/listContainer';
import ListSearch from '../ui/list/listSearch';
import { useListMeals } from './hooks/useListMeals';
import { Meal } from './types';

const MealsPage = () => {
  const navigate = useNavigate();

  const meals = useListMeals();

  const [query, setQuery] = useState('');
  const handleSearch = useCallback((q: string) => {
    setQuery(q);
  }, []);

  const sortedMeals = useMemo(() => {
    const reQuery = new RegExp(query, 'i');

    return meals.data?.filter(
        (meal) =>
          reQuery.test(meal.name) ||
          meal.labels.some((label) => reQuery.test(label))
      )
      .sort((mealA, mealB) => mealA.name.localeCompare(mealB.name));
  }, [meals, query]);

  const handleCloseCreate = useCallback(() => {
    navigate('.', { replace: true });
  }, [navigate]);

  return (
    <>
      <ListHeader>Meals</ListHeader>
      <ListSearch onSearch={handleSearch} />
      <ListContainer>
        {sortedMeals?.map((meal: Meal) => (
          <ListItem key={meal.id} onClick={() => navigate(meal.id)}>
            {meal.name}
            <div className="text-xs truncate text-slate-500 font-light">
              {meal.labels.join(', ')}
            </div>
          </ListItem>
        ))}
      </ListContainer>
      <FloatingAddButton onClick={() => navigate('new')} />
      <Routes>
        <Route path="new" element={<CreateMealModal onCancel={handleCloseCreate} onClose={handleCloseCreate} />} />
        <Route path=":mealId" element={<UpdateMealModal />} />
      </Routes>
    </>
  );
};

export default MealsPage;
