import { useCallback, useState } from 'react';
import TextBox from '../../form/textbox';

type ListSearchPropsType = {
  autofocus?: boolean;
  onSearch: (s: string) => void;
};

const ListSearch = ({ autofocus, onSearch }: ListSearchPropsType) => {
  const [query, setQuery] = useState('');
  const handleChange = useCallback(
    (q: string) => {
      setQuery(q);
      onSearch(q);
    },
    [onSearch]
  );

  return (
    <div className="relative m-2">
      <TextBox
        autofocus={!!autofocus}
        label="Search"
        onChange={handleChange}
        value={query}
      />
    </div>
  );
};

export default ListSearch;
