import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { Label, LabelEgg } from '../types';
import { createLabel } from '../api/createLabel';

export const useCreateLabel = () => {
  const { familyId, uid } = useAuth();

  return useCallback(
    (label: LabelEgg): Promise<Label> => {
      if (!uid || !familyId) {
        return Promise.reject();
      }

      return createLabel(label, uid, familyId);
    },
    [familyId, uid]
  );
};
