import { query, where } from 'firebase/firestore';
import { splitQuery } from '../../lib/splitQuery';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { Meal } from '../types';
import { db } from './db';

export const getMealsById = (
  familyId: string,
  ids: Array<string>,
  onMeals: OnSnapshotCallback<Record<string, Meal>>
) => {
  splitQuery(
    (subsetIds) => query(db(familyId), where('__name__', 'in', subsetIds)),
    ids
  ).then((queryResults) => {
    const mealsById = queryResults.reduce<Record<string, Meal>>(
      (results, d) => ({
        ...results,
        [d.id]: {
          id: d.id,
          ...d.data(),
        } as Meal,
      }),
      {}
    );

    onMeals(mealsById);
  });

  return ()=>null;
};
