import { useCallback, useMemo, useState } from 'react';
import Modal from '../ui/modal';
import ModalTitle from '../ui/modal/title';
import ModalBody from '../ui/modal/body';
import ModalFooter from '../ui/modal/footer';
import Pill from '../ui/pill';
import Button from '../form/button';
import { Label } from '../labels/types';
import { useListLabels } from '../labels/hooks/useListLabels';

type LabelModalPropsType = {
  allLabels: Array<Label>;
  title: string;
  labels: Array<Label> | undefined;
  onClose: () => void;
  onSetLabels: (labels: Array<Label>) => void;
};

const LabelModal = ({
  allLabels,
  onClose,
  onSetLabels,
  title,
  labels = [],
}: LabelModalPropsType) => {
  const labelIds = useMemo(() => new Set(labels.map(({ id }) => id)), [labels]);
  const [chosenLabels, setChosenLabels] = useState<Set<Label>>(
    new Set(allLabels.filter(({ id }) => labelIds.has(id)))
  );

  const handleClick = useCallback((label: Label) => {
    setChosenLabels((curr) => {
      const s = new Set(curr);

      if (s.has(label)) {
        s.delete(label);
      } else {
        s.add(label);
      }

      return s;
    });
  }, []);

  const handleSave = useCallback(() => {
    if (!chosenLabels.size) {
      onClose();
      return;
    }

    onSetLabels(Array.from(chosenLabels));
  }, [chosenLabels, onClose, onSetLabels]);

  const sortedLabels = useMemo(() => {
    return allLabels.sort((labelA, labelB) =>
      labelA.name.localeCompare(labelB.name)
    );
  }, [allLabels]);

  return (
    <Modal>
      <ModalTitle onClose={onClose}>{title}</ModalTitle>
      <ModalBody>
        <div className="flex flex-row justify-center flex-wrap">
          {sortedLabels.map((label) => (
            <Pill
              key={label.id}
              onClick={() => handleClick(label)}
              value={chosenLabels.has(label)}
            >
              {label.name}
            </Pill>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button use="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={!allLabels?.length}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// TODO: need to make this a better pattern
const LabelModalLoader = (props: Omit<LabelModalPropsType, 'allLabels'>) => {
  const { data: allLabels, loading } = useListLabels();

  if (loading || !allLabels?.length) {
    return null;
  }

  return <LabelModal {...props} allLabels={allLabels} />;
};

export default LabelModalLoader;
