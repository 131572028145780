import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { init as sentryInit } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import gitInfo from './gitInfo.json';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { firebaseInit } from './firebase';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { serviceWorkerHandler } from './notifications/ServiceWorkerUI';
import { unregisterBackgroundSync } from './bgSync/dailyMealReminder';

sentryInit({
  environment: process.env.NODE_ENV,
  release: `menu-magic@${gitInfo.hash}`,
  dsn:
    process.env.NODE_ENV === 'development'
      ? undefined
      : 'https://c7348306bec74a9596a11fe948125e77@o1235314.ingest.sentry.io/6385245',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.3,
});

firebaseInit();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

serviceWorkerRegistration.register({
  onUpdate: (reg) => {
    serviceWorkerHandler.update(reg);
  },
});

unregisterBackgroundSync();
