import { createContext, useCallback, useEffect, useState } from 'react';
import { signOut as firebaseSignOut } from 'firebase/auth';
import { configureScope, setTag, setUser } from '@sentry/react';
import { getAuth } from '../firebase';
import { addFamilyMember, getFamilyForUser } from './families';
import { getByToken } from '../invite/api';

export interface AuthContextType {
  uid: string | null;
  familyId: string | null;
  acceptInvite: (uid: string, inviteToken: string) => Promise<void>;
  signIn: (uid: string) => Promise<void>;
  signOut: () => Promise<void>;
  validateAccess: (familyId: string) => boolean;
}

export const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const firebaseAuth = getAuth();

  const [uid, setUid] = useState<string | null>(null);
  const [familyId, setFamilyId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const acceptInvite = useCallback(async (uid: string, inviteToken: string) => {
    return getByToken(inviteToken)
      .then((invite) => {
        if (!invite) {
          throw new Error('Could not find invite');
        }

        return invite.familyId;
      })
      .then((familyId) => {
        addFamilyMember(familyId, uid, inviteToken);
        return familyId;
      })
      .then((familyId) => {
        setFamilyId(familyId);
        setUid(uid);
      });
  }, []);

  const signOut = useCallback(async () => {
    return firebaseSignOut(firebaseAuth).finally(() => {
      setFamilyId(null);
      setUid(null);
    });
  }, [firebaseAuth]);

  const signIn = useCallback(async (uid: string) => {
    return getFamilyForUser(uid).then((family) => {
      setFamilyId(family.id);
      setUid(uid);
    });
  }, []);

  const validateAccess = useCallback((__familyId: string) => {
    return true;
  }, []);

  useEffect(() => {
    const firebaseAuth = getAuth();

    firebaseAuth.onAuthStateChanged((currentUser) => {
      if (!currentUser?.uid || uid) {
        setLoading(false);
        return;
      }

      getFamilyForUser(currentUser.uid)
        .then((family) => {
          setFamilyId(family.id);
          setUid(currentUser.uid);
        })
        .catch(() => {
          setFamilyId(null);
        })
        .finally(() => setLoading(false));
    });
  }, [uid]);

  useEffect(() => {
    if (!uid || !familyId) {
      configureScope((scope) => {
        scope.clear();
        scope.setUser(null);
      });
      return;
    }

    setTag('familyId', familyId);
    setUser({ id: uid });
  }, [familyId, uid]);

  return (
    <AuthContext.Provider
      value={{ uid, familyId, acceptInvite, signIn, signOut, validateAccess }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
