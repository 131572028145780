import {
  doc,
  updateDoc,
} from 'firebase/firestore';
import { omit } from '../../lib/omit';
import { Label } from '../types';
import { db } from './db';

export const updateLabel = async (familyId: string, label: Label): Promise<void> => {
  try {
    return updateDoc(doc(db(familyId), label.id), omit(['id'], label));
  } catch (e) {
    console.error('Error updating document', e);
  }

  throw new Error('Could not update');
};
