import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../ui/loadingSpinner';
import ListHeader from '../ui/list/listHeader';
import Card from '../ui/card';
import ListContainer from '../ui/list/listContainer';
import ListSectionHeader from '../ui/list/listSectionHeader';
import { DayOfWeek, Menu } from './types';
import FloatingAddButton from '../ui/floatingAddButton';
import ImageGenerator from '../ui/imageGenerator';
import CircleClose from '../icons/circleClose';
import MenuPickerModal from './menuPickerModal';
import { deepClone } from '../lib/deepClone';
import { useConfirmModal } from '../ui/confirm';
import WeekNavigatorStrip from './weekNavigatorStrip';
import TextArea from '../form/textarea';
import { useDebounce } from '../lib/useDebounce';
import { RatingSection } from './ratingSection';
import { Side } from '../sides/types';
import { useGetSidesById } from '../sides/hooks/useGetSidesById';
import { Meal } from '../meals/types';
import { useGetMealsById } from '../meals/hooks/useGetMealsById';
import { useUpdateMenu } from './hooks/useUpdateMenu';
import { useCreateMenu } from './hooks/useCreateMenu';
import { useGetWeeksMenu } from './hooks/useGetWeeksMenu';

const DayPage = ({
  menu,
  meals,
  sides,
}: {
  menu: Menu;
  meals: Array<Meal>;
  sides: Array<Side>;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const week = params.week as string;
  const day = params.day as DayOfWeek;

  const [showPicker, setShowPicker] = useState(
    !meals.length && !sides.length && !menu.days[day].notes
  );

  const updateMenu = useUpdateMenu();
  const createMenu = useCreateMenu();

  const handleSaveDay = useCallback(
    ({ mealIds, sideIds }) => {
      if (!day) {
        return;
      }

      const newMenu = deepClone<Menu>(menu);

      newMenu.days[day].dinnerIds = mealIds;
      newMenu.days[day].sideIds = sideIds;

      if ('id' in menu) {
        updateMenu(newMenu);
      } else {
        createMenu(newMenu);
      }

      setShowPicker(false);
    },
    [createMenu, day, updateMenu, menu]
  );

  const [notes, setNotes] = useState(menu.days[day].notes);
  const handleSaveNotes = useCallback(() => {
    const newMenu = deepClone<Menu>(menu);

    newMenu.days[day].notes = notes;

    if ('id' in menu) {
      updateMenu(newMenu);
    } else {
      createMenu(newMenu);
    }
  }, [day, menu, notes, createMenu, updateMenu]);

  const debouncedSaveNotes = useDebounce(handleSaveNotes);

  const handleChangeNotes = useCallback(
    (value) => {
      setNotes(value);
      debouncedSaveNotes();
    },
    [debouncedSaveNotes]
  );

  const handleRemoveMeal = useCallback(
    (removeId) => {
      if (!day) {
        return;
      }

      const newMenu = deepClone<Menu>(menu);

      const mealIds = newMenu.days[day].dinnerIds;
      newMenu.days[day].dinnerIds = mealIds?.filter(
        (mealId) => mealId !== removeId
      );

      if ('id' in menu) {
        updateMenu(newMenu);
      } else {
        createMenu(newMenu);
      }
    },
    [createMenu, day, menu, updateMenu]
  );

  const confirmModal = useConfirmModal();

  const handleConfirmRemoveMeal = useCallback(
    (removeId) => {
      confirmModal('Remove this meal?', 'No', 'Yes')
        .then((option) => {
          if (option === 'Yes') {
            handleRemoveMeal(removeId);
          }
        })
        .catch(() => null);
    },
    [confirmModal, handleRemoveMeal]
  );

  const handleRemoveSide = useCallback(
    (removeId) => {
      if (!day) {
        return;
      }

      const newMenu = deepClone<Menu>(menu);

      const sideIds = newMenu.days[day].sideIds;
      newMenu.days[day].sideIds = sideIds?.filter(
        (sideId) => sideId !== removeId
      );

      if ('id' in menu) {
        updateMenu(newMenu);
      } else {
        createMenu(newMenu);
      }
    },
    [createMenu, day, menu, updateMenu]
  );

  const handleConfirmRemoveSide = useCallback(
    (removeId) => {
      confirmModal('Remove this side?', 'No', 'Yes')
        .then((option) => {
          if (option === 'Yes') {
            handleRemoveSide(removeId);
          }
        })
        .catch(() => null);
    },
    [confirmModal, handleRemoveSide]
  );

  const handleAdd = useCallback(() => {
    setShowPicker(true);
  }, []);

  const generateMealHash = useCallback((meal) => {
    if (!meal) {
      return `${Date.now()}`;
    }

    return [meal.name, ...meal.labels, meal.url || ''].join();
  }, []);

  const handleBack = useCallback(() => {
    navigate(`../${params.week}`);
  }, [navigate, params]);

  return (
    <div className="min-h-screen flex flex-col">
      <ListHeader>
        <div className="flex align-center">
          <div>
            <button className="pr-button" onClick={handleBack}>
              &laquo;
            </button>
          </div>
          <div>
            Menu of {week}: {day}
          </div>
        </div>
      </ListHeader>
      <div className="flex flex-row items-stretch grow">
        <WeekNavigatorStrip />
        <div className="grow min-w-0">
          <ListContainer>
            {meals.length ? <ListSectionHeader>Meals</ListSectionHeader> : null}
            {meals.map((meal) => (
              <Card key={meal.id}>
                <div className="relative">
                  <button
                    className="absolute right-[-8px] top-[-10px] w-button-sm h-button-sm"
                    onClick={() => handleConfirmRemoveMeal(meal.id)}
                  >
                    <CircleClose />
                  </button>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <ImageGenerator data={generateMealHash(meal)} />
                      <div className="flex flex-col min-w-0 w-2/3">
                        <div className="break-words">{meal.name}</div>
                        <div className="text-slate-400">
                          {meal.labels?.join(', ')}
                        </div>
                      </div>
                    </div>
                    {meal.url && (
                      <div className="mt-4 pt-2 border-t border-slate-200 truncate">
                        <a
                          href={`../../recipes/?url=${encodeURIComponent(meal.url)}`}
                          className="text-harry-dark"
                        >
                          {meal.url}
                        </a>
                      </div>
                    )}
                    <RatingSection mealId={meal.id} />
                  </div>
                </div>
              </Card>
            ))}
            {sides.length ? <ListSectionHeader>Sides</ListSectionHeader> : null}
            {Object.values(sides).map((side) => (
              <Card key={side.id}>
                <div className="relative">
                  <button
                    className="absolute right-[-8px] top-[-10px] w-button-sm h-button-sm"
                    onClick={() => handleConfirmRemoveSide(side.id)}
                  >
                    <CircleClose />
                  </button>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="flex flex-col">
                        <div>{side.name}</div>
                      </div>
                    </div>
                    {side.url && (
                      <div className="mt-4 pt-2 border-t border-slate-200">
                        <a
                          href={`../../recipes/?url=${encodeURIComponent(side.url)}`}
                          className="text-harry-dark"
                        >
                          {side.url}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            ))}
            <ListSectionHeader>Notes</ListSectionHeader>
            <Card>
              <TextArea value={notes} onChange={handleChangeNotes} />
            </Card>
          </ListContainer>
        </div>
      </div>
      <FloatingAddButton onClick={handleAdd} />
      {showPicker && (
        <MenuPickerModal
          title={day}
          week={menu}
          day={day as DayOfWeek}
          onCancel={() => setShowPicker(false)}
          onSave={handleSaveDay}
        />
      )}
    </div>
  );
};

const DayPageLoader = () => {
  const params = useParams();
  const week = params.week as string;
  const day = params.day as DayOfWeek;

  const { data: menu, loading: menuLoading } = useGetWeeksMenu(week);

  const mealIds = useMemo(
    () => menu?.days[day]?.dinnerIds || [],
    [day, menu?.days]
  );
  const { data: mealsData, loading: mealsLoading } = useGetMealsById(mealIds);

  const sideIds = useMemo(
    () => menu?.days[day]?.sideIds || [],
    [day, menu?.days]
  );
  const { data: sidesData, loading: sidesLoading } = useGetSidesById(sideIds);

  const meals = useMemo(() => {
    return Object.values(mealsData || {}) || [];
  }, [mealsData]);

  const sides = useMemo(() => {
    return Object.values(sidesData || {}) || [];
  }, [sidesData]);

  if (
    menuLoading ||
    (mealsLoading && !meals.length) ||
    (sidesLoading && !sides.length)
  ) {
    return <LoadingSpinner />;
  }

  return (
    <DayPage
      key={`${week}-${day}`}
      menu={menu as Menu}
      meals={Object.values(mealsData || {}) || []}
      sides={Object.values(sidesData || {}) || []}
    />
  );
};

export default DayPageLoader;
