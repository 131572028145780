import { onSnapshot, query, where } from 'firebase/firestore';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { Recipe } from '../types';
import { db } from './db';

export const getRecipeByUrl = (
  url: string,
  onRecipe: OnSnapshotCallback<Recipe>
) => {
  const q = query(db(), where('url', '==', url));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    if (!querySnapshot.empty) {
      onRecipe({
        id: querySnapshot.docs[0].id,
        ...querySnapshot.docs[0].data(),
      } as Recipe);
    } else {
      onRecipe(null);
    }
  });

  return () => {
    unsubscribe();
  };
};
