import { useCallback, useState } from 'react';
import { MEAL, trackCreate } from '../tracking';
import Modal from '../ui/modal';
import ModalTitle from '../ui/modal/title';
import ModalBody from '../ui/modal/body';
import ModalFooter from '../ui/modal/footer';
import TextBox from '../form/textbox';
import Tag from '../ui/tag';
import Button from '../form/button';
import LabelInput from '../labels/labelInput';
import { useConfirmModal } from '../ui/confirm';
import { useCreateMeal } from '../meals/hooks/useCreateMeal';
import { useListMeals } from '../meals/hooks/useListMeals';

type CreateMealModalPropsType = {
  defaultName?: string;
  onCancel: () => void;
  onClose: (mealId: string) => void;
};

const CreateMealModal = ({
  defaultName = '',
  onCancel,
  onClose,
}: CreateMealModalPropsType) => {
  const [name, setName] = useState<string>(defaultName);
  const handleNameChange = (newName: string) => {
    setName(newName);
  };

  const createMeal = useCreateMeal();
  const allMeals = useListMeals();

  const [label, setLabel] = useState<string | null>();
  const [labels, setLabels] = useState<string[]>([]);
  const handleAddLabel = useCallback(() => {
    if (!label) {
      return;
    }

    setLabels((oldLabels) => [...oldLabels, label]);
    setLabel(null);
  }, [label]);

  const handleRemoveLabel = (value: string) => {
    setLabels((labels) => labels.filter((l) => l !== value));
  };

  const [url, setUrl] = useState<string>();
  const handleUrlChange = (newUrl: string) => {
    setUrl(newUrl);
  };

  const confirmModal = useConfirmModal();

  const handleSave = useCallback(() => {
    if (!name) {
      return;
    }

    trackCreate(MEAL);

    const existingMeal = allMeals.data?.find(
      (meal) => meal.name.toLocaleLowerCase() === name.toLocaleLowerCase()
    );

    let confirmPromise: Promise<string>;
    if (existingMeal) {
      confirmPromise = confirmModal(
        'A meal with this name already exists. Create anyway?',
        'No',
        'Yes'
      );
    } else {
      confirmPromise = Promise.resolve('Yes');
    }

    confirmPromise
      .then((answer) => {
        if (answer === 'Yes') {
          return createMeal({ name: name.trim(), labels, url: url || '' }).then(
            (meal) => {
              onClose(meal.id);
            }
          );
        }
      })
      .catch(() => {
        // NOOP
      });
  }, [
    allMeals,
    confirmModal,
    createMeal,
    name,
    labels,
    url,
    onClose,
  ]);

  const handleChangeLabel = useCallback(
    (value: string) => setLabel(value),
    [setLabel]
  );

  return (
    <Modal>
      <ModalTitle onClose={onCancel}>Create Meal</ModalTitle>
      <ModalBody>
        <div>
          <TextBox label="Name" onChange={handleNameChange} value={name} />
        </div>
        <div className="mt-2">
          <TextBox label="Recipe URL" onChange={handleUrlChange} value={url} />
        </div>
        <div className="flex flex-wrap gap-2 my-2">
          {labels.map((label) => (
            <Tag key={label} value={label} onRemove={handleRemoveLabel} />
          ))}
        </div>
        <div className="mt-2">
          <LabelInput
            label={label || ''}
            onAdd={handleAddLabel}
            onChange={handleChangeLabel}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button use="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Create</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateMealModal;
