import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../ui/modal';
import ModalTitle from '../ui/modal/title';
import ModalBody from '../ui/modal/body';
import ModalFooter from '../ui/modal/footer';
import TextBox from '../form/textbox';
import Tag from '../ui/tag';
import Button from '../form/button';
import { useDebounce } from '../lib/useDebounce';
import LabelInput from '../labels/labelInput';
import OpenNew from '../icons/open-new';
import LoadingSpinner from '../ui/loadingSpinner';
import { Meal } from '../meals/types';
import { useUpdateMeal } from '../meals/hooks/useUpdateMeal';
import { useDeleteMeal } from '../meals/hooks/useDeleteMeal';
import { useGetMealById } from '../meals/hooks/useGetMealById';

type UpdateMealModalPropsType = {
  meal: Meal;
};

const UpdateMealModal = ({ meal }: UpdateMealModalPropsType) => {
  const navigate = useNavigate();

  const handleClose = useCallback(
    () => navigate('../', { replace: true }),
    [navigate]
  );

  const updateMeal = useUpdateMeal();

  const [name, setName] = useState<string>(meal.name);
  const [url, setUrl] = useState<string>(meal.url || '');

  const debounceSave = useDebounce(() => {
    if (name) {
      updateMeal({ ...meal, name: name.trim(), url });
    }
  });
  const handleNameChange = (newName: string) => {
    setName(newName);
    debounceSave();
  };
  const handleUrlChange = (newUrl: string) => {
    setUrl(newUrl);
    debounceSave();
  };

  const [newLabel, setNewLabel] = useState<string | null>();
  const handleAddLabel = useCallback(() => {
    if (!meal || !newLabel) {
      return;
    }

    updateMeal({
      ...meal,
      labels: [...meal.labels, newLabel],
    });

    setNewLabel(null);
  }, [meal, newLabel, updateMeal]);

  const handleRemoveLabel = useCallback(
    (value) => {
      if (!meal) {
        return;
      }

      updateMeal({
        ...meal,
        labels: meal.labels.filter((label) => label !== value),
      });
    },
    [meal, updateMeal]
  );

  const deleteMeal = useDeleteMeal();
  const handleDeleteMeal = useCallback(() => {
    if (!meal) {
      return;
    }

    deleteMeal(meal.id).then(handleClose);
  }, [deleteMeal, meal, handleClose]);

  const handleChangeNewLabel = useCallback(
    (value: string) => setNewLabel(value),
    [setNewLabel]
  );

  const handleGoToRecipe = useCallback(() => {
    if (!url) {
      return;
    }

    window.open(url);
  }, [url]);

  return (
    <Modal>
      <ModalTitle onClose={handleClose}>Update Meal</ModalTitle>
      <ModalBody>
        {!meal ? (
          'loading'
        ) : (
          <>
            <div>
              <TextBox label="Name" onChange={handleNameChange} value={name} />
            </div>
            <div className="mt-2 flex items-center">
              <TextBox
                label="Recipe URL"
                onChange={handleUrlChange}
                value={url || undefined}
              />
              <button
                className="w-button h-button ml-2"
                disabled={!url}
                onClick={handleGoToRecipe}
              >
                <OpenNew className={!url ? 'fill-slate-400' : undefined} />
              </button>
            </div>
            <div className="flex flex-wrap gap-2 my-2">
              {meal.labels.map((label) => (
                <Tag key={label} value={label} onRemove={handleRemoveLabel} />
              ))}
            </div>
            <div className="mt-2">
              <LabelInput
                label={newLabel || ''}
                onAdd={handleAddLabel}
                onChange={handleChangeNewLabel}
              />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button use="destructive" onClick={handleDeleteMeal}>
          Delete
        </Button>
        <Button use="secondary" onClick={handleClose} disabled={!meal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const UpdateMealModalLoader = () => {
  const { mealId = '' } = useParams();
  const { data: meal, loading } = useGetMealById(mealId);

  if (loading) {
    return <LoadingSpinner />;
  }

  return <UpdateMealModal meal={meal!} />;
};

export default UpdateMealModalLoader;
