import { useCallback } from 'react';

type TextAreaOnChangeHandler = (value: string) => void;

type TextAreaPropsType = {
  autofocus?: boolean;
  onChange: TextAreaOnChangeHandler;
  placeholder?: string;
  value?: string;
};

const TextArea = ({ onChange, value = '' }: TextAreaPropsType) => {
  const handleChange = useCallback(
    ({
      target: { value: newValue },
    }: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <div className="relative flex flex-col h-18 w-full">
      <textarea
        onChange={handleChange}
        value={value}
        className="bg-slate-100 border-slate-600 border-b rounded-t-sm w-full h-full px-2 pt-2 pb-2 outline-none resize-none focus:border-harry-dark focus:bg-slate-200"
      />
    </div>
  );
};

export default TextArea;
