import { useCallback } from 'react';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { useAuth } from '../../auth/useAuth';
import { listSides } from '../api/listSides';
import { Side } from '../types';

export const useListSides = () => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<Array<Side>>) => {
      if (!familyId) {
        handleData(null);
        return;
      }

      return listSides(familyId, handleData);
    },
    [familyId]
  );

  return useDataSubscriber(fetcher);
};
