import { useCallback } from 'react';

type TabConfig = {
  id: string;
  label: string;
};

type TabPropsType = TabConfig & {
  active: boolean;
  onChange: (tabId: string) => void;
};

const Tab = ({ active, id, label, onChange }: React.PropsWithoutRef<TabPropsType>) => {
  const handleTabChange = useCallback(() => {
    onChange(id);
  }, [id, onChange]);

  return <div className={`grow p-2 text-center border-b-2 ${active ? 'border-ron bg-slate-100' : 'border-slate-400 bg-slate-200 text-slate-400'}`} onClick={handleTabChange}>{label}</div>;
};

type TabBarPropsType = {
  activeId: string;
  tabs: Array<TabConfig>;
  onChange: (tabId: string) => void;
};

const TabBar = ({ activeId, tabs, onChange }: TabBarPropsType) => (
  <div className="flex flex-row flex-nowrap items-stretch">
    {tabs.map(({ id, label }) => (
      <Tab key={id} active={id===activeId} id={id} label={label} onChange={onChange} />
    ))}
  </div>
);

export default TabBar;
