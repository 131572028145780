import { useCallback, useEffect, useState } from 'react';

type DataLoaderOptionsType = {
  skip?: boolean;
};

export const ERROR_MISSING = 'ERROR_MISSING';

type DataLoaderErrorCode = typeof ERROR_MISSING;

type DataLoaderErrorType = {
  code: DataLoaderErrorCode;
};

export type DataLoaderResponseType<T> = {
  error?: DataLoaderErrorType;
  loading: boolean;
  data: T;
};

export type FetcherType<T> = (
  setData: (d: T) => void,
  setLoading: (b: boolean) => void,
  setError: (e: DataLoaderErrorType) => void
) => void;

export const useDataLoader = <T>(
  fetcher: FetcherType<T>,
  opts?: DataLoaderOptionsType
): DataLoaderResponseType<T> => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<DataLoaderErrorType>(null!);
  const [data, setData] = useState<T>(null!);

  const handleError = useCallback((code) => {
    setError({ code });
  }, []);

  const handleData = useCallback((newData) => {
    setData(newData);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (opts?.skip) {
      setLoading(false);
      return;
    }

    setLoading(true);

    return fetcher(handleData, setLoading, handleError);
  }, [fetcher, opts, handleData, handleError]);

  return { data, loading, error };
};

export type DataSubscriberOptions<R extends Record<keyof R, any>> = {
  skip?: boolean;
  variables: R;
};

export type OnSnapshotCallback<T> = (data: T | null) => void;
export type OnSnapshotUnsubscribe = (() => void) | undefined;

export const useDataSubscriber = <T, R>(
  fetcher: (handleData: (data: T | null) => void, variables?: DataSubscriberOptions<R>['variables']) => OnSnapshotUnsubscribe,
  opts?: DataSubscriberOptions<R>
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<DataLoaderErrorType|null>();
  const [data, setData] = useState<T | null>();

  const handleError = useCallback((code) => {
    setError({ code });
  }, []);

  const handleData = useCallback((newData: T | null) => {
    setData(newData);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (opts?.skip) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const unsubscribe = fetcher(handleData, opts?.variables);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [fetcher, opts, handleData, handleError]);

  return { data, loading, error };
};
