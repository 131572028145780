import { useCallback, useMemo, useState } from 'react';
import { useListMeals } from '../meals/hooks/useListMeals';
import ListItem from '../ui/list/listItem';
import ListContainer, { EMBEDDED } from '../ui/list/listContainer';
import ListSearch from '../ui/list/listSearch';
import Plus from '../icons/plus';
import CreateMealModal from '../createMealModal';
import Confirm from '../icons/confirm';

type MealPickerPropsType = {
  mealIds: Array<string>;
  onToggleItem: (mealId: string) => void;
};

const MODE_CREATE = 'CREATE';
const MODE_PICK = 'PICK';

const MealPicker = ({ mealIds, onToggleItem }: MealPickerPropsType) => {
  const meals = useListMeals();

  const [mode, setMode] = useState(MODE_PICK);

  const handleToggleItem = useCallback(
    (mealId: string) => {
      onToggleItem(mealId);
      setMode(MODE_PICK);
    },
    [onToggleItem]
  );

  const [query, setQuery] = useState('');
  const handleSearch = useCallback((q: string) => {
    setQuery(q);
  }, []);

  const sortedMeals = useMemo(() => {
    const reQuery = new RegExp(query, 'i');

    if (!meals.data) {
      return [];
    }

    return meals.data
      .filter(
        (meal) =>
          reQuery.test(meal.name) ||
          meal.labels.some((label) => reQuery.test(label))
      )
      .sort((mealA, mealB) => mealA.name.localeCompare(mealB.name));
  }, [meals, query]);

  const handleCancelCreate = useCallback(() => {
    setMode(MODE_PICK);
    setQuery('');
  }, []);

  const mealIdSet = useMemo(() => new Set(mealIds), [mealIds]);

  return mode === MODE_PICK ? (
    <ListContainer use={EMBEDDED}>
      <ListSearch onSearch={handleSearch} autofocus={true} />
      {query && (
        <ListItem onClick={() => setMode(MODE_CREATE)}>
          <div className="flex flex-row items-center">
            <div className="bg-ron rounded-full mr-2 w-button-sm h-button-sm">
              <Plus className="stroke-white stroke-[3px]" />
            </div>
            <div>Create "{query}"</div>
          </div>
        </ListItem>
      )}
      {sortedMeals.map((meal) => (
        <ListItem key={meal.id} onClick={() => handleToggleItem(meal.id)}>
          <div className="flex flex-row items-center">
            <div className="w-button-sm h-button-sm mr-2">
              <Confirm
                color={
                  mealIdSet.has(meal.id) ? 'stroke-ron' : 'stroke-slate-200'
                }
              />
            </div>
            <div>{meal.name}</div>
          </div>
        </ListItem>
      ))}
    </ListContainer>
  ) : (
    <CreateMealModal
      defaultName={query}
      onCancel={handleCancelCreate}
      onClose={(mealId) => handleToggleItem(mealId)}
    />
  );
};

export default MealPicker;
