import {
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { db } from './db';

export const deleteLabel = async (familyId: string, id: string): Promise<void> => {
  try {
    return deleteDoc(doc(db(familyId), id));
  } catch (e) {
    console.error('Error deleting document: ', e);
  }

  throw new Error('Could not delete document');
};
