import { useDataSubscriber } from '../../lib/useDataLoader';
import { useAuth } from '../../auth/useAuth';
import { useCallback } from 'react';
import { getMealAverageRating } from '../api/getMealAverageRating';

export const useGetMealAverageRating = (mealId: string) => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (
      handleData: (avgData: { total: number; average: number } | null) => void
    ) => {
      if (!familyId) {
        return () => null;
      }

      return getMealAverageRating({ familyId, mealId }, handleData);
    },
    [mealId, familyId]
  );

  return useDataSubscriber(fetcher);
};
