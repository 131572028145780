import { useEffect, useState } from 'react';
import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { getDb } from '../firebase';

export type InviteTokenType = string;

export type InviteType = {
  token: string;
  familyId: string;
};

export const getByToken = async (
  token: InviteTokenType
): Promise<InviteType | null> => {
  if (!token) {
    return Promise.resolve(null);
  }

  const docRef = doc(getDb(), 'invitations', token);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      token: docSnap.id,
      ...docSnap.data(),
    } as InviteType;
  }

  return null;
};

export const useGetInviteByToken = (
  token: InviteTokenType
): InviteType | null => {
  const [invite, setInvite] = useState<InviteType>(null!);

  useEffect(() => {
    getByToken(token).then((v) => v && setInvite(v));
  }, [token]);

  return invite;
};

export const createInvite = async (uid: string, familyId: string) => {
  const inviteDoc = await addDoc(collection(getDb(), 'invitations'), {
    createdAt: Date.now(),
    familyId,
    uid,
  });

  return inviteDoc.id;
};
