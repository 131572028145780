import { getItem, setItem } from "./localStorage"

const LAST_WEEK = 'LAST_WEEK' as const;

export const saveWeek = (week: string) => {
  setItem(LAST_WEEK, week);
};

export const restoreWeek = () => {
  return getItem(LAST_WEEK);
};
