import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { APP, FAMILY, trackInvite } from '../tracking';
import gitInfo from '../gitInfo.json';
import { useAuth } from '../auth/useAuth';
import { createFamily } from '../auth/families';
import { createInvite } from '../invite/api';
import Profile from '../icons/profile';
import FlyOutMenu from './flyOutMenu/flyOutMenu';
import FlyOutMenuItem from './flyOutMenu/flyOutMenuItem';
import FlyOutMenuFooter from './flyOutMenu/flyOutMenuFooter';
import Modal from './modal';
import ModalTitle from './modal/title';
import ModalBody from './modal/body';

const ProfileMenu = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const handleSignOut = useCallback(() => {
    auth.signOut().then(() => navigate('/login'));
  }, [auth, navigate]);

  const tetherRef = useRef<HTMLButtonElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleToggleMenu = () => setIsMenuOpen((oldOpen) => !oldOpen);

  const [showQrCode, setShowQrCode] = useState<string | null>(null);
  const handleCloseQrCode = useCallback(() => setShowQrCode(null), []);

  const handleInviteFamily = useCallback(() => {
    if (!auth.uid || !auth.familyId) {
      return;
    }

    trackInvite(FAMILY);

    createInvite(auth.uid, auth.familyId).then((inviteId) => {
      setShowQrCode(
        `${window.location.protocol}//${window.location.host}/invite/${inviteId}`
      );
      setIsMenuOpen(false);
    });
  }, [auth.uid, auth.familyId]);

  const handleInviteApp = useCallback(() => {
    if (!auth.uid) {
      return;
    }

    const { uid } = auth;

    trackInvite(APP);

    createFamily(uid)
      .then((familyId) => createInvite(uid, familyId))
      .then((inviteId) => {
        setShowQrCode(
          `${window.location.protocol}//${window.location.host}/invite/${inviteId}`
        );
        setIsMenuOpen(false);
      });
  }, [auth]);

  return (
    <>
      <button
        ref={tetherRef}
        className="block w-[20px] h-[20px]"
        onClick={handleToggleMenu}
      >
        <Profile className="stroke-white fill-white" />
      </button>
      {isMenuOpen && (
        <FlyOutMenu tetherRef={tetherRef} onClose={handleToggleMenu}>
          <FlyOutMenuItem onClick={handleInviteFamily}>
            Invite to your family
          </FlyOutMenuItem>
          <FlyOutMenuItem onClick={handleInviteApp}>
            Invite to the app
          </FlyOutMenuItem>
          <FlyOutMenuItem onClick={handleSignOut}>Sign out</FlyOutMenuItem>
          <FlyOutMenuFooter>
            v{gitInfo.version}-{gitInfo.hash}
          </FlyOutMenuFooter>
        </FlyOutMenu>
      )}
      {showQrCode && (
        <Modal>
          <ModalTitle onClose={handleCloseQrCode}>Invite QR Code</ModalTitle>
          <ModalBody>
            <div className="flex justify-center">
              <QRCode value={showQrCode} />
            </div>
            <div className="text-black w-full mt-4 outline-none select-all">
              {showQrCode}
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default ProfileMenu;
