import { useEffect, useState } from 'react';

const Dots = ({ count }: { count: number }) => {
  const dots = [];

  for (let i = 0; i < count; i++) {
    dots.push('.');
  }

  return <>{dots.join(' ')}</>;
};

const LoadingLocal = () => {
  const [dots, setDots] = useState(1);

  useEffect(() => {
    const timeoutId = window.setInterval(
      () => setDots((oldDots) => (oldDots === 4 ? 1 : oldDots + 1)),
      1000
    );

    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  return <Dots count={dots} />;
};

export default LoadingLocal;
